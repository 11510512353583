h3.ant-typography{
  u {
    -webkit-text-decoration-color: red; /* Safari */  
    text-decoration-color: #0A8FDC;
    text-decoration-thickness: 3px;
 
  }
}

.ant-card-body{
  padding: 24px !important;
}
@primary-color: #0A8FDC;