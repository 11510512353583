/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.apps-wrap {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}
.apps-wrap-header {
  display: flex;
  align-items: center;
  margin-bottom: 16px;
}
@media screen and (min-width: 1600px) {
  .apps-wrap-header {
    margin-bottom: 20px;
  }
}
.apps-wrap-header h2 {
  color: #111827;
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 0;
}
.menu-btn {
  display: block;
  margin-right: 8px;
  padding: 0;
  background-color: transparent;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  border: 0 none;
  width: 35px;
  height: 35px;
}
[dir=rtl] .menu-btn {
  margin-right: 0;
  margin-left: 8px;
}
.menu-btn:hover,
.menu-btn:focus {
  background-color: transparent;
  color: #111827;
}
@media screen and (min-width: 992px) {
  .menu-btn {
    display: none;
  }
}
.menu-btn .anticon {
  font-size: 20px;
}
.apps-container {
  display: flex;
  height: calc(100vh - 138px);
}
.appMainFixedFooter .apps-container {
  height: calc(100vh - 184px) !important;
}
@media screen and (min-width: 768px) {
  .apps-container {
    height: calc(100vh - 161px);
  }
  .appMainFixedFooter .apps-container {
    height: calc(100vh - 207px);
  }
}
@media screen and (min-width: 992px) {
  .apps-container {
    height: calc(100vh - 165px);
  }
  .appMainFixedFooter .apps-container {
    height: calc(100vh - 211px);
  }
}
@media screen and (min-width: 1200px) {
  .apps-container {
    height: calc(100vh - 153px);
  }
  .appMainFixedFooter .apps-container,
  .appMainFooter .apps-container {
    height: calc(100vh - 199px);
  }
}
.apps-main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .apps-main-content {
    width: calc(100% - 17.5rem);
    padding-left: 32px;
  }
  [dir=rtl] .apps-main-content {
    padding-left: 0;
    padding-right: 32px;
  }
}
@media screen and (min-width: 992px) {
  .apps-main-content.appsMainContentFull {
    width: 100%;
    padding-left: 0;
  }
  [dir=rtl] .apps-main-content.appsMainContentFull {
    padding-right: 0;
  }
}
.apps-content-container {
  display: flex;
  flex-direction: column;
  height: calc(100% - 56px) !important;
}
@media screen and (min-width: 768px) {
  .apps-content-container {
    height: calc(100% - 64px) !important;
  }
}
.apps-content-container.fullView {
  height: 100% !important;
}
.apps-content-container .scrum-absolute {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
[dir=rtl] .apps-content-container .scrum-absolute {
  left: auto;
  right: 0;
}
.apps-content-container .react-trello-board {
  height: 100%;
  background-color: #f5f5f5 !important;
}
.apps-content-container .react-trello-board > div {
  height: 100%;
}
.apps-content-container .scrum-row,
.apps-content-container .react-trello-board > div > .smooth-dnd-container.horizontal {
  display: inline-flex;
  min-width: 100%;
  height: 100%;
  margin-left: -10px;
  margin-right: -10px;
}
.apps-content-container .scrum-col,
.apps-content-container .react-trello-board .smooth-dnd-container .react-trello-lane {
  min-width: 280px;
  max-width: 280px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 16px;
  background-color: rgba(255, 255, 255, 0.45);
  height: 100% !important;
  min-height: 100%;
}
@media screen and (min-width: 768px) {
  .apps-content-container .scrum-col,
  .apps-content-container .react-trello-board .smooth-dnd-container .react-trello-lane {
    min-width: 354px;
    max-width: 354px;
  }
}
.apps-content-container .react-trello-board .smooth-dnd-container .react-trello-lane > div:not(.scrum-board-list-header-card) {
  align-self: normal;
  overflow: hidden;
  max-height: none;
}
.apps-content-container .scroll-scrum-item {
  height: auto !important;
}
.apps-content-container .react-trello-board .smooth-dnd-container.vertical {
  overflow-y: auto;
  height: calc(100% - 72px);
}
.apps-main-content-card {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.apps-main-content-card > .ant-card-body {
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
.apps-footer {
  padding: 8px 24px;
  border-top: 1px solid rgba(0, 0, 0, 0.12);
}
.apps-header {
  padding: 4px 24px;
  min-height: 56px;
  display: flex;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.12);
}
@media screen and (min-width: 768px) {
  .apps-header {
    min-height: 64px;
  }
}
.apps-sidebar {
  height: 100%;
  display: none;
  flex-direction: column;
}
@media screen and (min-width: 992px) {
  .apps-sidebar {
    width: 17.5rem;
    display: flex;
  }
}
@media screen and (min-width: 1600px) {
  .apps-sidebar {
    width: 17.5rem;
  }
}
.apps-sidebar-drawer .ant-drawer-content-wrapper {
  width: 17.5rem !important;
}
.apps-sidebar-drawer .ant-drawer-body {
  padding: 0;
}
.apps-sidebar-card {
  display: none;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .apps-sidebar-card {
    flex: 1;
    display: flex;
    flex-direction: column;
  }
}
.apps-sidebar-card .ant-card-body {
  padding: 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
}
